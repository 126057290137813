import React, { Component } from "react";


import Layout from "../components/layout"
import Cnews from "../components/others/contentNews"
import { navigate } from "gatsby"


import { StaticQuery, graphql } from "gatsby"
import Moment from "moment";
import es from "moment/locale/es";

Moment.locale("es", es);
const objectGraphql = graphql`
{
  allStrapiNoticias(filter: {locale: {eq: "en"}}) {
   edges {
     node {
       id
      titulo
       
      Tags {
        nombre
        id
      }
       
      fecha
      min_descripcion
      descripcion
      imagen {
        url
      }
     }
   }
 }
}
`


class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { search: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ search: event.target.value });
  }

  handleSubmit(event) {
    navigate(
      "/en/search",
      {
        state: { search: this.state.search }
      }
    )
    event.preventDefault();
  }

  render() {


    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout props={this.props.props}>
              <section className="titulo-int-noticia">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2>Search</h2>
                    </div>
                    <div className="col-md-9 text-right b-none">
                    </div>
                  </div>
                </div>
              </section>
              <section className="padding-section-int-not">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <Cnews location={this.props.props.location} text={{
                        title: "News",
                        search: 'Search results',
                        noSearch: 'Your search yielded no results',
                        locate:'en',
                        nameUrl:"news"
                      }} news={data.allStrapiNoticias.edges} />
                    </div>

                    <div className="col-lg-4 notas-lateral">
                      <div id="block-custom-search-blocks-2">

                        <h4>Search</h4>

                        <form onSubmit={this.handleSubmit} className="form-inline buscador" id="custom-search-blocks-form-2" accept-charset="UTF-8" target="_self">
                          <input type="text" defaultValue={this.state.search} onChange={this.handleChange} title="Ingresa el texto a buscar" className="form-control" placeholder="Ingresa el texto a buscar" type="text" id="edit-custom-search-blocks-form-2--2" name="custom_search_blocks_form_2" size="15" maxlength="128" />
                          <input className="form-submit btn btn-primary" type="submit" value="S" />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default Search
